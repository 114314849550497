/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ApplicantIncome, PeriodicValue, PeriodKey, SalaryEmployment, SelfEmployment } from '@/types';
import type { ApiSchema, FrequencyType } from '@/types/api';
import { mapToApi } from '@/mappers';
import { periodType } from '@/helpers/const';

const mapFrequency: Record<PeriodKey, FrequencyType> = {
  week: 'weekly',
  fortnight: 'fortnightly',
  month: 'monthly',
  quarter: 'quarterly',
  year: 'annually',
};

export const fundsSalaried = (data: SalaryEmployment, applicantIndex: number): any => {
  return {
    id: data.id,
    applicantIndex,
    salaryPackagedAmount: 0,
    salaryAmount: data.salary?.amount,
    salaryFrequency: data.salary?.frequency ? mapFrequency[data.salary?.frequency] : undefined,
    commissionsAmount: data.commission?.amount,
    commissionsFrequency: data.commission?.frequency ? mapFrequency[data.commission?.frequency] : undefined,
    bonusesAmount: data.bonuses?.amount,
    bonusesFrequency: data.bonuses?.frequency ? mapFrequency[data.bonuses.frequency] : undefined,
    overtimeAmount: data.overtime?.amount,
    overtimeFrequency: data.overtime?.frequency ? mapFrequency[data.overtime?.frequency] : undefined,
    nameOfEmployer: data.employerName,
    employerLine1: data.employerAddress?.addressLine,
    employerLine2: data.employerAddress?.addressLine2 ?? '',
    employerCity: data.employerAddress?.locality,
    employerState: mapToApi.australianState(data.employerAddress?.state),
    employerCountry: 'au',
    employerPostCode: data.employerAddress?.postcode ?? undefined,
    isSalarySacrifice: data.salarySacrifice,
    jobType: data.type,
    employedSince: data.employedSince,
  };
};

export const fundsSelfEmployed = (data: SelfEmployment, applicantIndex: number): any => {
  return {
    id: data.id,
    applicantIndex,
    salaryAmount: data.salary.amount,
    salaryFrequency: mapFrequency[data.salary.frequency],
    line1: data.businessAddress.addressLine,
    line2: data.businessAddress.addressLine2 ?? '',
    city: data.businessAddress.locality,
    state: mapToApi.australianState(data.businessAddress.state),
    postCode: data.businessAddress.postcode,
    country: 'au',
    businessType: data.businessType,
    tradingName: data.tradingName,
    occupation: data.occupation,
    industry: data.industry,
    isGSTRegistered: data.gstRegistered,
    australianBusinessNumber: data.abn,
    isSoleTrader: data.soleTrader,
    hasTaxReturnsLastTwoFinancialYears: data.hasTaxReturns,
    employedSince: data.employedSince,
  };
};

export const fundsAdditionalIncome = (data: ApplicantIncome | null, applicantIndex: number): any[] => {
  if (!data) return [];

  const getPeriodLabel = (frequency: keyof typeof periodType): string | undefined => {
    return periodType[frequency]?.label;
  };

  const incomeTypes = [
    { key: 'centrelinkPayments', type: 'centrelink_payments' },
    { key: 'childSupportMaintenance', type: 'child_support_maintenance' },
    { key: 'familyAllowance', type: 'family_allowance' },
    { key: 'familyTaxBenefit', type: 'family_tax_benefits' },
    { key: 'governmentPension', type: 'government_pension' },
    { key: 'dividendsInvestments', type: 'dividends' },
    { key: 'superannuation', type: 'superannuation' },
    { key: 'other', type: 'other' },
  ] as const;

  const incomeEntries = (incomeTypes || [])
    .map(({ key, type }) => {
      const incomeData = data[key as keyof ApplicantIncome] as PeriodicValue | undefined;
      if (incomeData && typeof incomeData === 'object' && 'amount' in incomeData && incomeData.amount !== 0) {
        return {
          id: data.id,
          applicantIndex: applicantIndex,
          amount: incomeData.amount,
          incomeType: type,
          frequency: getPeriodLabel(incomeData.frequency as keyof typeof periodType),
          isTaxable: false,
        };
      }
      return null;
    })
    .filter((entry) => entry !== null);
  return incomeEntries;
};

export const incomeExpectation = (
  data: ApplicantIncome | null,
  applicantIndex: number,
): (
  | ApiSchema['CustomerApplicationsSubmitApplicantIncomeExpectationDtoV1']
  | ApiSchema['CustomerApplicationsSubmitApplicantIncomeExpectationDtoV2']
)[] =>
  data
    ? [
        {
          id: data.incomeExpectation?.id,
          applicantIndex,
          expectedChangesInNext12Months: data.expectIncomeChange,
          financialPosition: data.effectOnIncomeChange,
        },
      ]
    : [];

export const employmentDetail = (data: ApplicantIncome | null, applicantIndex: number): any[] =>
  data
    ? [
        {
          id: data.employmentDetail?.id,
          applicantIndex,
          employmentChangeInShortTerm: data.shortTermChange,
          detailsOnChange: data.shortTermEmploymentChange,
        },
      ]
    : [];
